<template>
	<Table
		:pgLimitGet="pgLimit"
		backendDirectory="chamado/listar"
		:busca="{
            busca: busca.busca || null,
            idusuarioconclusao: busca.idusuarioconclusao || null,
            idstatus: busca.idstatus || null,
            idfilial: busca.idfilial || null,
            idcategoria: busca.idcategoria || null,
            idsubcategoria: busca.idsubcategoria || null,
            iddepartamento: busca.iddepartamento || null,
            dtini: `${busca.dtinicio} 00:00:00`,
            dtfim: `${busca.dtfim} 23:59:59`,
        }"
		:filter="true"
		:keydown="keydown"
		:refresh="true"
		:time="90000"
		:headers="{
            idchamado: {nome: 'ID', tipo: 'link', link: '/chamado/get/'},
            chamado: {nome: 'Chamado', tipo: 'texto'},
            descricao: {nome: 'Descrição', tipo: 'texto'},
            filial: {nome: 'Filial', tipo: 'texto'},
            departamento: {nome: 'Departamento', tipo: 'texto'},
            usuariocadastro: {nome: 'Usuário', tipo: 'texto'},
            dataabertura: {nome: 'Data de abertura', tipo: 'texto'},
            dataconclusao: {nome: 'Data de retorno', tipo: 'texto'},
            status: {nome: 'Status', tipo: 'condicionais', class: 'text-center', condicionais: [
                {condicao: 'Aberto', cor: 'yellow darken-4'},
                {condicao: 'Em andamento', cor: 'amber darken-4'},
                {condicao: 'Aguardando terceiros', cor: 'orange darken-4'},
                {condicao: 'Concluído', cor: 'green darken-4'},
            ]},
        }"
	>
		<v-col class="d-none d-md-block pr-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="status.lista"
				item-text="status"
				item-value="idstatus"
				label="Status"
				outlined
				dense
				v-model="busca.idstatus"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="departamentos.lista"
				item-text="departamento"
				item-value="iddepartamento"
				label="Departamento"
				outlined
				dense
				v-model="busca.iddepartamento"
				@change="listarCategorias(),listarUsuarios()"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="categorias.lista"
				item-text="categoria"
				item-value="idcategoria"
				label="Categorias"
				outlined
				dense
				v-model="busca.idcategoria"
				@change="listarSubCategorias()"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="subCategorias.lista"
				item-text="subcategoria"
				item-value="idsubcategoria"
				label="Sub categoria"
				outlined
				dense
				v-model="busca.idsubcategoria"
			></v-select>
		</v-col>
		<v-col class="d-none d-md-block px-1">
			<v-select
				clearable
				:items="filiais.lista"
				item-text="filial"
				item-value="idfilial"
				label="Filial"
				outlined
				dense
				v-model="busca.idfilial"
			></v-select>
		</v-col>
        <v-col class="d-none d-md-block px-1" v-if="busca.iddepartamento == 1">
            <v-select
                clearable
                label="Atendente"
                v-model="busca.idusuarioconclusao"
                outlined
                dense
                :items="usuarios.lista"
                item-text="usuario"
                item-value="idusuario"
            ></v-select>
        </v-col>
		<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
			<v-sheet class="text-center" height="100%">
				<v-row class="pa-3">
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="departamentos.lista"
							item-text="departamento"
							item-value="iddepartamento"
							label="Departamento"
							outlined
							dense
							v-model="busca.iddepartamento"
							@change="listarCategorias()"
						></v-select>
					</v-col>
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="filiais.lista"
							item-text="filial"
							item-value="idfilial"
							label="Filial"
							outlined
							dense
							v-model="busca.idfilial"
						></v-select>
					</v-col>
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="status.lista"
							item-text="status"
							item-value="idstatus"
							label="Status"
							outlined
							dense
							v-model="busca.idstatus"
						></v-select>
					</v-col>
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="categorias.lista"
							item-text="categoria"
							item-value="idcategoria"
							label="Categoria"
							outlined
							dense
							v-model="busca.idcategoria"
							@change="listarSubCategorias()"
						></v-select>
					</v-col>
					<v-col cols="6" class="pr-3 pr-md-0 mb-n8">
						<v-select
							clearable
							:items="subCategorias.lista"
							item-text="subcategoria"
							item-value="idsubcategoria"
							label="Sub categoria"
							outlined
							dense
							v-model="busca.idsubcategoria"
						></v-select>
					</v-col>
                    <v-col cols="6" class="pr-3 pr-md-0 mb-n8">
                        <v-select
                            clearable
                            label="Atendente"
                            v-model="busca.idusuarioconclusao"
                            outlined
                            dense
                            :items="usuarios.lista"
                            item-text="usuario"
                            item-value="idusuario"
                        ></v-select>
                    </v-col>
					<v-col cols="6">
						<InputDatePicker v-model="busca.dtinicio" label="Data inicial" :outlined="true" :dense="true" />
					</v-col>
					<v-col cols="6">
						<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn text class="mt-n3" color="primary" @click="keydown = !keydown, sheet = !sheet">Filtrar</v-btn>
					</v-col>
				</v-row>
			</v-sheet>
		</v-bottom-sheet>
		<v-col class="pl-1">
			<v-text-field
				class="d-flex d-md-none"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				append-outer-icon="mdi-menu"
				@click:append-outer="sheet = !sheet"
				@keydown.enter="keydown = !keydown"
			/>
			<v-text-field
				class="d-none d-md-block"
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import Table from "../Widgets/Table";

export default {
	name: "ChamadoRecebidoLista",
	mixins: [mixinFilial],
	components: { Table, InputDatePicker },
	data: () => ({
		sheet: false,
		keydown: false,
		status: { lista: [] },
		departamentos: { lista: [] },
		categorias: { lista: [] },
		subCategorias: { lista: [] },
        usuarios: { lista: [] },
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			iddepartamento: 0,
			idcategoria: 0,
			idfilial: 0,
			idsubcategoria: 0,
			idstatus: 0,
            idusuarioconclusao:0,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
		listarStatus() {
			this.status.lista = [];
			return axios
				.post(`${this.backendUrl}chamado/status`)
				.then((res) => {
					if (res.data.lista != null) {
						this.status = res.data;
					}
				});
		},
		listarDepartamentos() {
			this.departamentos.lista = [];
			return axios
				.post(`${this.backendUrl}departamento/listar`, {
					limit: 9999,
					offset: 0,
					busca: null,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.departamentos = res.data;
					}
				});
		},
		listarCategorias() {
			this.categorias.lista = [];
			return axios
				.post(`${this.backendUrl}chamado/categoria`, {
					iddepartamento: this.busca.iddepartamento,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.categorias = res.data;
					}
				});
		},
		listarSubCategorias() {
			this.subCategorias.lista = [];
			return axios
				.post(`${this.backendUrl}chamado/subcategoria`, {
					idcategoria: this.busca.idcategoria,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.subCategorias = res.data;
					}
				});
		},
        listarUsuarios() {
            this.usuarios.lista = [];
            return axios
                .post(`${this.backendUrl}usuario/listar`, {
                    limit: 99999,
                    offset: 0,
                    notidgrupo: 3,
                    idfilial: 1,
                    iddepartamento: this.busca.iddepartamento,
                })
                .then((res) => {
                    if (res.data.lista != null) {
                        this.usuarios = res.data;
                        this.usuarios.lista = this.usuarios.lista.filter(
                            (v) => v.ativo == "F"
                        );
                    }
                });
        },
		async init() {
			this.busca.iddepartamento = this.usuario.iddepartamento;
			await this.listarStatus();
			await this.listarDepartamentos();
            await this.listarUsuarios();
			await this.listarCategorias();
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>